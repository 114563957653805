
import { actions as formActions } from 'react-redux-form';

import { actions } from "../../BasicReducer";
import { RequestParams } from "../../utils/RequestParams";
import { notificationActions } from "../../Notifications";
import api from "../PagesAPI";
import { t } from "../../I18N";
import * as types from "./actionTypes";



export function loadPages() {
  return async (dispatch) => {
    const pages = await api.get(new RequestParams());
    dispatch(actions.set('pages', pages));
  };
}

export function resetPage() {
  return (dispatch) => {
    dispatch(formActions.reset('page.data'));
    dispatch(formActions.setInitial('page.data'));
  };
}

export function updateValue(model, value) {
  return (dispatch) => {
    dispatch(formActions.change(`page.data${model}`, value));
  };
}

export function savePage(data, navigate) {
  return (dispatch) => {
    dispatch({ type: types.SAVING_PAGE });
    return api.
    save(new RequestParams(data)).
    then(async (response) => {
      dispatch(
        notificationActions.notify(t('System', 'Saved successfully.', null, false), 'success')
      );
      dispatch(
        formActions.merge('page.data', {
          _id: response._id,
          sharedId: response.sharedId,
          _rev: response._rev
        })
      );
      dispatch({ type: types.PAGE_SAVED, data: response });
      navigate(`/settings/pages/edit/${response.sharedId}`);
    }).
    catch(() => {
      dispatch({ type: types.PAGE_SAVED, data: {} });
    });
  };
}

export function deletePage(page) {
  return (dispatch) =>
  api.delete(new RequestParams({ sharedId: page.sharedId })).then(() => {
    dispatch(actions.remove('pages', page));
  });
}

export function changeUrl(url) {
  return (dispatch) => {
    dispatch(formActions.change('page.data.url', url));
  };
}

export function getPrevPage(url) {
  return api.getByUrl(url).then((result) => {
    console.log(result);
  });
}

export function setUrlError(validity) {
  return (dispatch) => {
    dispatch(formActions.setValidity('pages.data.url', validity));
  };
}