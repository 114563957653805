function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { useEffect, useState } from 'react';
import update from 'immutability-helper';


import { omit } from 'lodash';

import {
  addActiveItem,
  removeActiveItem,
  mapWithID,
  mapWithParent,
  sortActiveItems } from "./DnDDefinitions";


/* eslint-disable comma-spacing */
const useDnDContext = (
type,
operations,
initialItems = [],
sourceItems = []) =>
{
  const [activeItems, setActiveItems] = useState(
    mapWithParent(
      initialItems,
      undefined,
      operations.itemsProperty,
      operations.allowEditGroupsWithDnD
    )
  );
  const [internalChange, setInternalChange] = useState(false);
  const [dragging, setDragging] = useState(false);

  const [availableItems, setAvailableItems] = useState(
    mapWithID(sourceItems || [])
  );
  const itemsProperty = operations.itemsProperty || 'items';

  const updateItem = (item) => {
    setInternalChange(true);
    setActiveItems((prevActiveItems) => {
      const index = prevActiveItems.findIndex((x) => x.dndId === item.dndId);
      return update(prevActiveItems, {
        [index]: {
          $set: item
        }
      });
    });
  };

  useEffect(() => {
    if (internalChange === true && !dragging && operations.onChange !== undefined) {
      const sortedItems = activeItems.
      filter((item) => item).
      map((item) => {
        const values = item.value.items ?
        item.value.items.map((subItem) =>
        omit(subItem.value, ['dndId', 'items', operations.itemsProperty || ''])
        ) :
        [];
        return _objectSpread(_objectSpread({},
        omit(item.value, ['items', 'dndId', operations.itemsProperty || ''])),
        operations.itemsProperty && values.length > 0 ?
        { [operations.itemsProperty]: values } :
        {});

      });
      operations.onChange(sortedItems);
    } else {
      setInternalChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItems, dragging]);

  const state = {
    activeItems,
    setActiveItems,
    availableItems,
    setAvailableItems,
    itemsProperty,
    operations
  };
  const dndContext = {
    type,
    addItem: addActiveItem(state),
    removeItem: removeActiveItem(state),
    sort: sortActiveItems(state),
    updateItem,
    updateActiveItems: (items) => {
      const updatedItems = mapWithParent(items, undefined, itemsProperty);
      setActiveItems(updatedItems);
      setInternalChange(false);
      return updatedItems;
    },
    activeItems,
    availableItems,
    getDisplayName: operations.getDisplayName,
    itemsProperty,
    operations,
    setDragging
  };
  return dndContext;
};

export { useDnDContext };