



const checkSortArea = (
monitor,
hoverBoundingRect,
dragIndex,
hoverIndex) =>
{
  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%

  // Dragging downwards OR Dragging upwards
  if (
  dragIndex < hoverIndex && hoverClientY < hoverMiddleY ||
  dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
  {
    return true;
  }
  return false;
};

const isOutOfSortArea = (
ref,
monitor,
dragIndex,
hoverIndex) =>

// Determine rectangle on screen
// No hoverBoundingRect AND Don't replace items with themselves
!ref.current ||
!ref.current.getBoundingClientRect() ||
dragIndex === hoverIndex ||
checkSortArea(monitor, ref.current.getBoundingClientRect(), dragIndex, hoverIndex);

const isNotSortableItem = (
currentItem,
target) =>

currentItem.item.parent && !target.parent ||
currentItem.dndId === target.dndId ||
currentItem.item.container === undefined;

const hoverSortable =
(
ref,
target,
index,
setDragging,
sortFunction) =>

(
currentItem,





monitor) =>
{
  setDragging(true);
  const dragIndex = currentItem.index;
  const hoverIndex = index;
  const draggingDown = monitor.getDifferenceFromInitialOffset().y > 0;
  const invalidSort =
  isNotSortableItem(currentItem, target) ||
  draggingDown && dragIndex > hoverIndex && hoverIndex === 0 ||
  isOutOfSortArea(ref, monitor, dragIndex, hoverIndex);

  if (!ref.current || !sortFunction || !monitor.isOver({ shallow: false }) || invalidSort) {
    return;
  }
  // Time to actually perform the action
  sortFunction(currentItem.item, target, dragIndex, hoverIndex);

  // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.
  // eslint-disable-next-line no-param-reassign
  currentItem.index = hoverIndex;
};

export { hoverSortable };