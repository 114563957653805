
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";



const get = async (language, headers) => {
  try {
    const requestParams = new RequestParams({}, headers);
    api.locale(language);
    const response = await api.get('pages', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

const getBySharedId = async (
sharedId,
language,
headers) =>
{
  try {
    const requestParams = new RequestParams({ sharedId }, headers);
    api.locale(language);
    const response = await api.get('page', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

const save = async (
page,
headers) =>
{
  try {
    const requestParams = new RequestParams(page, headers);
    const response = await api.post('pages', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};

const deleteBySharedId = async (sharedId, headers) => {
  try {
    const response = await api.delete('pages', new RequestParams({ sharedId }, headers));
    return response.json;
  } catch (e) {
    return e;
  }
};

export { get, getBySharedId, deleteBySharedId, save };