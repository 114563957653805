const _excluded = ["json", "status", "cookie", "headers", "endpoint"];function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var n = Object.getOwnPropertySymbols(e);for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (e.indexOf(n) >= 0) continue;t[n] = r[n];}return t;}import 'cross-fetch/polyfill';
import superagent from 'superagent';

import rison from 'rison-node';
import { assign } from 'lodash';
import { getResponseType } from "./apiClient/httpResponses";

let cookie;

class FetchResponseError extends Error {
  // eslint-disable-next-line no-shadow, max-statements
  constructor(message, details = {}) {
    super(message);
    const { json, status, cookie: cookieRes, headers, endpoint } = details,rest = _objectWithoutProperties(details, _excluded);
    const errorType = getResponseType(status);
    this.name = errorType === 'ClientError' ? 'Client Error' : 'Server Error';
    this.json = json;
    this.status = status;
    this.cookie = cookieRes;
    this.headers = headers;
    this.endpoint = endpoint;
    this.requestId = json === null || json === void 0 ? void 0 : json.requestId;
    this.additionalInfo = rest;
  }
}

const attemptRisonDecode = (string) => {
  const errcb = (e) => {
    throw Error(`rison decoder error: ${e}`);
  };

  const risonParser = new rison.parser(errcb); // eslint-disable-line new-cap
  risonParser.error = (message) => {
    this.message = message;
  };

  risonParser.parse(string);
};

export function toUrlParams(_data) {
  if (typeof _data === 'string') {
    return `?${_data}`;
  }

  const data = _objectSpread({}, _data);
  if (!data || Object.keys(data).length === 0) {
    return '';
  }

  return `?${Object.keys(data).
  map((key) => {
    if (typeof data[key] === 'undefined' || data[key] === null) {
      return;
    }

    if (typeof data[key] === 'object') {
      data[key] = JSON.stringify(data[key]);
    }

    let encodedValue = encodeURIComponent(data[key]);

    if (encodeURIComponent(key) === 'q') {
      try {
        attemptRisonDecode(data[key]);
        encodedValue = data[key];
      } catch (err) {
        encodedValue = encodeURIComponent(data[key]);
      }
    }
    return `${encodeURIComponent(key)}=${encodedValue}`;
  }).
  filter((param) => param).
  join('&')}`;
}

const removeUndefinedKeys = (obj) => {
  //eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((key) => obj[key] === undefined ? delete obj[key] : {});
};

const _fetch = (url, data, method, _headers) => {
  let response;
  let params = '';
  let body;

  const headers = _objectSpread({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Cookie: cookie },
  _headers);


  if (method === 'GET' || method === 'DELETE') {
    params = toUrlParams(data);
  }

  if (method === 'POST' || method === 'PUT') {
    body = JSON.stringify(data);
  }

  if (URLSearchParams && data instanceof URLSearchParams) {
    body = data;
  }

  removeUndefinedKeys(headers);

  return fetch(url + params, {
    method,
    headers,
    credentials: 'same-origin',
    body
  }).
  then((res) => {
    let setCookie;
    if (res.headers.get('set-cookie')) {
      setCookie = res.headers.get('set-cookie');
    }
    response = res;
    // Failed .json() parsing usually indicates a non-success http status,
    // so we rather return that failure status than throw our own parsin
    // error.
    return Promise.all([res.json().catch(() => ({})), setCookie, res.headers]);
  }).
  then(([json, setCookie, responseHeaders]) => {
    const processedResponse = assign(
      {
        json,
        status: response.status,
        cookie: setCookie,
        headers: responseHeaders,
        endpoint: { method, url }
      },
      !response.ok ?
      {
        ok: response.ok,
        message: json.message || response.statusText
      } :
      {}
    );
    if (!response.ok) {
      throw new FetchResponseError(
        `Request failed with status code ${processedResponse.status}: ${processedResponse.message}`,
        processedResponse
      );
    }

    return processedResponse;
  });
};

export default {
  post: (url, data, headers) => _fetch(url, data, 'POST', headers),

  put: (url, data, headers) => _fetch(url, data, 'PUT', headers),

  get: (url, data, headers) => _fetch(url, data, 'GET', headers),

  delete: (url, data, headers) => _fetch(url, data, 'DELETE', headers),

  head: (url, data, headers) => _fetch(url, data, 'HEAD', headers),

  // TEST!!!!! Fully untested function
  uploadFile: (url, filename, file, _cookie) =>
  new Promise((resolve, reject) => {
    superagent.
    post(url).
    set('Accept', 'application/json').
    set('X-Requested-With', 'XMLHttpRequest').
    set('Cookie', _cookie || cookie || '').
    attach('file', file, filename).
    then((response) => {
      resolve(response);
    }).
    catch((err) => {
      reject(err);
    });
  }),

  cookie: (c) => {
    cookie = c;
  }
};

export { FetchResponseError };