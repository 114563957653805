function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}
import { get, has, omit } from 'lodash';
import update from 'immutability-helper';

import ID from "../../../../../shared/uniqueID";

































const setIdAndParent = (item, parent) => {
  const idField = has(item.value, 'dndId') ? 'dndId' : 'id';
  const dndId = has(item.value, idField) ? get(item.value, idField) : ID();
  return _objectSpread(_objectSpread({}, item), {}, { dndId }, parent ? { parent } : {});
};

const mapWithParent = (
items,
parent,
itemsProperty = 'items',
allowEditGroupsWithDnD = true) =>

items.map((item) => {
  const draggableItem = _objectSpread({
    value: item },
  parent === undefined ?
  { container: 'root', fixed: !allowEditGroupsWithDnD } :
  { fixed: !allowEditGroupsWithDnD });

  const subItems = get(draggableItem.value, itemsProperty);
  const itemWithId = setIdAndParent(draggableItem, parent);
  if (subItems && subItems.length > 0) {
    return _objectSpread(_objectSpread({},
    itemWithId), {}, {
      value: _objectSpread(_objectSpread({},
      itemWithId.value), {}, {
        items: mapWithParent(
          subItems,
          itemWithId,
          itemsProperty,
          allowEditGroupsWithDnD
        ) }) });


  }
  return itemWithId;
});

const mapWithID = (items) =>
items.map((item) => setIdAndParent(item));

const removeChildFromParent = (state, newItem) => {
  if (newItem.parent) {
    const indexOfCurrentParent = state.activeItems.findIndex(
      (ai) => ai.dndId === newItem.parent.dndId
    );
    state.setActiveItems((prevActiveItems) => {
      const index = prevActiveItems[indexOfCurrentParent].value.items.findIndex(
        (ai) => ai.dndId === newItem.dndId
      );
      return update(prevActiveItems, {
        [indexOfCurrentParent]: { value: { items: { $splice: [[index, 1]] } } }
      });
    });
  }
};

const findItemIndex = (items, searchedItem) =>
items.findIndex((item) => item.dndId === searchedItem.dndId);

const removeItemFromList = (
setActiveItems,
indexOfNewItem) =>
{
  if (indexOfNewItem > -1) {
    setActiveItems((prevActiveItems) =>
    update(prevActiveItems, {
      $splice: [[indexOfNewItem, 1]]
    })
    );
  }
};

const addItemToParent = (
state,
indexOfNewItem,
newItem,
parent) =>
{
  removeItemFromList(state.setActiveItems, indexOfNewItem);
  state.setActiveItems((prevActiveItems) => {
    const indexOfParent = findItemIndex(prevActiveItems, parent);
    if (indexOfParent > -1) {
      return prevActiveItems[indexOfParent].value.items ?
      update(prevActiveItems, {
        [indexOfParent]: {
          value: {
            items: {
              $push: [_objectSpread(_objectSpread({},

              omit(newItem, ['parent', 'container', 'value.items'])), {}, {
                parent })]


            }
          }
        }
      }) :
      update(prevActiveItems, {
        [indexOfParent]: {
          value: {
            items: {
              $set: [_objectSpread(_objectSpread({}, newItem), {}, { parent })]
            }
          }
        }
      });
    }
    return prevActiveItems;
  });
};

const addActiveItem =
(state) =>
(newItem, parent) => {
  removeChildFromParent(state, newItem);
  const indexOfNewItem = findItemIndex(state.activeItems, newItem);
  if (parent) {
    addItemToParent(state, indexOfNewItem, newItem, parent);
  } else if (indexOfNewItem === -1) {
    state.setActiveItems((prevActiveItems) =>
    update(prevActiveItems, {
      //@ts-ignore
      $push: [omit(newItem, ['parent', 'container', 'value.items'])]
    })
    );
  }
  const indexOfSource = findItemIndex(state.availableItems, newItem);
  removeItemFromList(state.setAvailableItems, indexOfSource);
};

const removeActiveItem =
(state) =>
(item) => {
  if (item.parent !== undefined) {
    removeChildFromParent(state, item);
  } else {
    const index = findItemIndex(state.activeItems, item);
    removeItemFromList(state.setActiveItems, index);
  }
  const availableSubItems = (item.value.items || []).map((ai) =>
  omit(ai, ['parent', 'container'])
  );
  const releasedItem = omit(item, ['parent', 'container', 'value.items']);
  state.setAvailableItems((prevAvailableItems) =>
  update(prevAvailableItems, {
    //@ts-ignore
    $push: [releasedItem, ...availableSubItems]
  })
  );
};

const sortChildren = (
state,
{
  currentItem,
  target,
  dragIndex,
  hoverIndex
}) =>
{
  const indexOfParent = findItemIndex(state.activeItems, currentItem.parent);
  const targetIndex = findItemIndex(state.activeItems[indexOfParent].value.items || [], target);
  if (targetIndex === hoverIndex) {
    state.setActiveItems((prevActiveItems) =>
    update(prevActiveItems, {
      [indexOfParent]: {
        value: {
          items: {
            $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevActiveItems[indexOfParent].value.items[dragIndex]]]

          }
        }
      }
    })
    );
  }
};

const sortParents = (
state,
{
  dragIndex,
  hoverIndex
}) =>
{
  state.setActiveItems((prevActiveItems) =>
  update(prevActiveItems, {
    $splice: [
    [dragIndex, 1],
    [hoverIndex, 0, prevActiveItems[dragIndex]]]

  })
  );
};
const sortActiveItems =
(state) =>
(currentItem, target, dragIndex, hoverIndex) => {
  if (currentItem.parent !== undefined) {
    sortChildren(state, { currentItem, target, dragIndex, hoverIndex });
  } else {
    sortParents(state, { currentItem, target, dragIndex, hoverIndex });
  }
  if (state.operations.sortCallback) {
    state.operations.sortCallback(state.activeItems.map((item) => item.value));
  }
};


export { addActiveItem, removeActiveItem, sortActiveItems, mapWithParent, mapWithID };